const { REACT_APP_ADMIN_BASE_URL: ENV_ADMIN_BASE_URL, REACT_APP_EMBROIDERY_BASE_URL: ENV_EMBROIDERY_BASE_URL } = process.env;

const Config = {
    ADMIN_BASE_URL: 'https://localhost:7248/',
    DYNAMIC_METHOD_SUB_URL: 'api/v1/dynamic/dataoperation/',
    COMMON_CONTROLLER_SUB_URL: 'api/v1/common/',
    SITE_TITLE: 'Austin Regional Clinic',
    QUALTRICS_URL: 'https://uniformadvantage.qualtrics.com/jfe/form/SV_6A7RWmmrgC2uKiO?Q_CHL=si&Q_CanScreenCapture=1',
    QUALTRICS_TIMER_MS: 10000,

    END_POINT_NAMES: {
        CREATE_BASKET: 'create-basket',
        GET_SIZE_LIST: 'get-size-list',
        GET_CATEGORIES_LIST: 'get-categories-list',
        GET_RECENTS_PRODUCTS_LIST: 'get-recents-products-list',
        GET_POPULAR_PRODUCTS_LIST: 'get-popular-products-list',
        GET_RELATED_PRODUCTS_LIST: 'get-related-products-list',
        GET_POPULAR_CATEGORIES: 'get-popular-categories',
        GET_PAYMENT_METHODS: 'get-payment-methods',
        GET_COLORS_LIST: 'get-colors-list',
        INSERT_PRODUCT_REVIEW: 'insert-product-review',
        SIGNUP_USER: 'signup-user',
        CREATE_VENDOR_REQUEST: 'create-vendor-request',
        CONTACT_US: 'post-contact-us',
        GET_USER_LOGIN: 'get-user-login',
        GET_EMPLOYEE_ID_LOGIN_INFO: 'get-employee-id-login-info',
        UPDATE_EMPLOYEE_ID_LOGIN_INFO: 'update-employee-id-login-info',
        REFRESH_TOKEN: 'refresh-token',
        ME: 'me',
        GET_ORDER_DETAIL: 'get-order-detail',
        INSERT_SUBSCRIBER: 'insert-subscriber',
        GET_PRODUCT_REVIEWS: 'get-product-reviews',
        GET_MANUFACTURER_LIST: 'get-manufacturer-list',
        GET_TAGS_LIST: 'get-popular-tags',
        GET_All_PRODUCTS: 'get-all-products',
        GET_PRODUCT_DETAIL: 'get-product_detail',
        GET_COUNTRIES_LIST: 'get-countries-list',
        GET_CITIES_LIST: 'get-cities-list',
        GET_STATES_PROVINCES_LIST: 'get-states-provinces-list',
        GET_PRODUCTS_LIST_BY_IDS: 'get-products-list-by-ids',
        POST_CUSTOMER_ORDER: 'post-order/post-customer-order',
        GET_HOME_SCREEN_BANNER: 'get-home-screen-banner',
        EDIT_PROFILE: 'edit-profile',
        UPDATE_PROFILE: 'update-profile',
        GET_WEB_CAMPAIGN_LIST: 'get-web-campaign-list',
        GET_WEB_CAMPAIGN_DETAIL: 'get-web-campaign-detail',
        GET_CUSTOMER_ORDER_HISTORY_MASTER: 'get-customer-order-history-master',
        GET_CUSTOME_ORDER_HISTORY_DETAIL: 'get-customer-order-history-detail',
        VALIDATE_ADDRESS: 'validate-address',
        SUGGEST_ADDRESS: 'suggest-address',
        VALIDATE_EMAIL_SEND_OTP: 'validate-email-send-otp',
        VALIDATE_OTP_CHANGE_PASSWORD: 'validate-otp-change-password',
        GET_STRP_PUB_KEY: 'get-strp-pub-key',
        GET_WEBSITE_LOGO: 'get-website-logo',
        GET_PRODUCT_ALL_ATTRIBUTES_BY_ID: 'get-product-all-attributes-by-productId',
        GET_CUSTOMER_CART_ITEMS: 'get-customer-cart-items/cart-data',
        GET_COUPON_CODE_DISCOUNT: 'get-coupon-code-discount-value/calculate-coupon-discount',
        GET_LOCALIZATION_CSTM_PORTAL: 'localization-cstm-portal/get-localization-data',
        EN_UR_DROW_PASS_RNDOM: 'en-ur-drow-pass-rndom/en-ur-drow-pass-rndom',
        DOWNLOAD_DIGITAL_FILE: 'download-digital-file',
        GET_COLOR_INVENTORY: 'get-color-inventory',
        ADD_TO_CART: 'add-to-cart',
        REMOVE_FROM_CART: 'remove-from-cart',
        GET_CART: 'get-cart',
        GET_CART_DETAIL: 'get-cart-detail',
        GET_CUSTOMER_ADDRESS: 'get-customer-address',
        GET_SHIP_TO_LOCATIONS: 'get-ship-to-locations',
        GET_EMPLOYEE_CREDIT_BALANCE: 'get-employee-credit-balance',
        TOKENIZE_CC: 'tokenize-cc',
        CONFIRM_ORDER: 'confirm-order',
        PLACE_ORDER: 'place-order',
        GET_CUSTOMER_ORDERS: 'get-customer-orders',
        REGISTER_CUSTOMER: 'register-customer',
        GET_CONTENT_BY_ID: 'get-content-by-id',
        CHANGE_PASSWORD: 'change-password',
        CHANGE_LINE_QUANTITY: 'change-line-quantity',
        INIT_PASSWORD_RESET: 'init-password-reset',
        SITE_CONFIG: 'site-config',
        EDIT_LINE_ITEM_PRODUCT: 'edit-line-product',
        GET_SHIPPING_METHODS: 'get-shipping-methods',
        EDIT_ADDRESS: 'edit-address',
        REMOVE_ADDRESS: 'remove-address',
        CALCULATE_SHIPPING: 'calculate-shipping',
        SWITCH_SESSION:'switch-session'
    },

    ERROR_MESSAGES: {
        CHECKOUT_GET_ADDRESS_ERROR: "There was an error getting Addresses for your account. Please refresh the page. If this continues, contact an administrator.",
        HTTP501_NO_BASKET_REGEX: /^No basket with ID (.*) could be found.$/,
        HTTP501_CARTID_MISSING: "The given key 'CartID' was not present in the dictionary.",
        HTTP501_UNKNOWN_BASKET_ERROR: "Resource '/baskets/' is unknown. Please provide a valid resource.",
        HTTP501_UNKNOWN_BASKET_ERROR_MSG: "Error loading your cart. Please login and try again.",
        NO_HQ_ADDRESS: 'Default billing address is not configured for this organization. Please contact UA.',
        PDP_UPDATE_EMBROIDERY_ERROR_REGEX: /^The product item for product item ID (.*) couldn\'t be found.$/,
        PDP_UPDATE_EMBROIDERY_ERROR: "The product item is no longer in the Shopping Cart.",
        CART_PRODUCT_NOT_AVAILABLE: "Product is no longer available"
    },

    ORDER_STATUS: {
        Active: 1,
        InProgress: 2,
        Completed: 3,
        Returned: 4,
        Refunded: 5
    },

    APP_SETTING: {
        DefaultCurrencyCode: "USD",
        DefaultCurrencySymbol: "$"
    },

    PRODUCT_ATTRIBUTE_TYPES_ENUM: {
        FixedValue: 1,
        Percentage: 2
    },
    PRODUCT_ATTRIBUTE_ENUM: {
        Color: 1,
        Size: 2,
        EmbroideryMeta: 3,
        EmbroideryPrice: 4,
        Length: 5
    },

    LANG_CODES_ENUM: {
        English: "en",
        Spanish: "es"
    },

    LANG_CODES_IDS_ENUM: {
        English: 1,
        Spanish: 2
    },

    ENV_ADMIN_BASE_URL, // Buried, so no one can see
    ENV_EMBROIDERY_BASE_URL
}
export default Config;
