export const LOADER_DURATION = 700;
export const PAYMENT_INSTRUMENTS = {
    CREDIT_CARD: 'Credit Card',
    UAB_EMPLOYEE_CREDITS: 'Employee Credits',
    UAB_PAYROLL_DEDUCTION: 'Payroll Deduction',
    ON_ACCOUNT: 'On Account',
}
export const ORDER_STATUS = {
    0: 'New',
    1: 'Processing',
    2: 'Shipped',
    3: 'Cancelled',
}

export const CARD_MAPPING = {
    "visa": "visa",
    "master card": "mastercard",
    "master": "mastercard",
    "american express": "amex",
    "discover": "discover",
};
