import { api } from './api.ts'
import Config from '../helpers/Config'
import { Site, SiteLogo } from '../types/site.ts'

// Define a service using a base URL and expected endpoints
export const siteApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSiteInfo: build.query({
            query: () => {
                const params = {
                    requestParameters: {
                        Data: []
                    }
                }
                return {
                    url: Config.END_POINT_NAMES.SITE_CONFIG,
                    method: 'POST',
                    body: params,
                    providesTags: (result = []) => [
                        ...result.map(({ id }) => ({ type: 'SiteInfo', id } as const)),
                        { type: 'SiteInfo' as const, id: 'LIST' },
                    ],
                }
            },
            transformResponse: (response: any): Site => {
                return {
                    id: response.data?.SiteID,
                    name: response.data?.SiteName,
                    isChatEnabled: response.data?.ChatEnabled,
                    isSubmitOrderEnabled: response.data?.SubmitOrderEnabled,
                    selfRegister: response.data?.SelfRegister,
                    loginSystem: response.data?.LoginSystem,
                    allLocations: response.data?.AllLocations.map((location: any) => ({
                        address1: location.Address1,
                        address2: location.Address2,
                        city: location.City,
                        stateCode: location.State,
                        countryCode: 'US',
                        postalCode: location.Zip,
                        phone: location.Phone,
                        axLocationId: location.AXLocationAcountID,
                        locationId: location.LocationID
                    })),
                    defaultLocation: {
                        firstName: response.data?.DefaultLocation.FirstName,
                        lastName: response.data?.DefaultLocation.LastName,
                        fullName: response.data?.DefaultLocation.FirstName + ' ' + response.data?.DefaultLocation.LastName,
                        address1: response.data?.DefaultLocation.Address1,
                        address2: response.data?.DefaultLocation.Address2,
                        city: response.data?.DefaultLocation.City,
                        stateCode: response.data?.DefaultLocation.State,
                        countryCode: 'US',
                        postalCode: response.data?.DefaultLocation.Zip,
                        phone: response.data?.DefaultLocation.Phone,
                        axLocationId: response.data?.DefaultLocation.AXLocationAcountID,
                        locationId: response.data?.DefaultLocation.LocationID,
                        c_addressType: response.data?.DefaultLocation.c_addressType,
                        c_isSmartyValidated: response.data?.DefaultLocation.c_isSmartyValidated
                    },
                    pbeEnabled: response.data?.PBEEnabled,
                    qualtricsEnabled: response.data?.QualtricsEnabled,
                    displayPBERecipientListOnPDP: response.data?.DisplayPBERecepientListOnPDP,
                    shipToLocationEnabled: response.data?.ShipToLocationEnabled,
                    isPayrollDeductionEnabled: response.data?.IsPayrollDeductionEnabled,
                    smartyACSettings: {
                        ...response.data?.SmartyACSettings,
                    },
                    smartyAVSSettings: {
                        ...response.data?.SmartyAVSSettings,
                    },
                    billingAddressEditable: response.data?.BillingAddressEditable,
                    shippingAddressEditable: response.data?.ShippingAddressEditable,
                    embChoiceDisplayDefault: response.data?.EmbChoiceDisplayDefault,
                    embChoiceDisplayGreaterThan: response.data?.EmbChoiceDisplayGreaterThan,
                    embChoiceDisplayGreaterThanOverride: response.data?.EmbChoiceDisplayGreaterThanOverride,
                    embChoiceDropdownDefaultOptionText: response.data?.EmbChoiceDropdownDefaultOptionText,
                    minSizeOptionsForDropdown: response.data?.MinSizeOptionsForDropdown,
                    categories: response.data?.Categories,
                    trackingLink: response.data?.TrackingLink,
                    additionalSettings: response.data?.AdditionalSettings || {},
		            enableSSO: response.data?.EnableSSO,
                    samlLoginEndpoint: response.data?.SAMLLoginEndpoint,
                    samlLogoutEndpoint: response.data?.SAMLLogoutEndpoint,
                    sizingPortalEnabled : response.data?.SizingPortalEnabled,
                    orderConfirmationMessage : response.data?.OrderConfirmationMessage,
                    isShippingTaxExempt: response.data?.IsShippingTaxExempt,
                    isTaxExemptAppliedToMaxItem: response.data?.IsTaxExemptAppliedToMaxItem,
                    listIgnoreTaxExempt: response.data?.ListIgnoreTaxExempt,
                    shipToLocationAddressFallback: response.data?.ShipToLocationAddressFallback,
                    payrollDeductionSelectOptions: response.data?.PayrollDeductionSelectOptions
                }
            }
        }),
        getSiteLogo: build.query({
            query: (payload) => {
                const params = {
                    requestParameters: {
                        recordValueJson: "[]"
                    }
                }
                return {
                    url: Config.END_POINT_NAMES.GET_WEBSITE_LOGO,
                    method: 'POST',
                    body: params,
                    providesTags: (result = []) => [
                        ...result.map(({ id }) => ({ type: 'SiteInfo', id } as const)),
                        { type: 'SiteInfo' as const, id: 'LIST' },
                    ],
                }
            },
            transformResponse: (response: any): SiteLogo => {
                return {
                    id: response.data?.[0].AppConfigKey,
                    url: response.data?.[0].AppConfigValue,
                } as SiteLogo
            }
        }),
        getLocaleData: build.query({
            query: (payload) => {
                const params = {
                    requestParameters: {
                        entityId: payload.entityId,
                        languageCode: payload.languageCode,
                        recordValueJson: "[]",
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_LOCALIZATION_CSTM_PORTAL,
                    method: 'POST',
                    body: params,
                    providesTags: (result = []) => [
                        ...result.map(({ id }) => ({ type: 'Locales', id } as const)),
                        { type: 'Locales' as const, id: 'LIST' },
                    ],
                }
            }
        }),
        getCategories: build.query({
            query: (payload) => {
                const params = {
                    requestParameters: {
                        PageNo: 1,
                        PageSize: 100,
                        recordValueJson: "[]",
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_CATEGORIES_LIST,
                    method: 'POST',
                    body: params,
                    providesTags: ['Categories']
                }
            },
            transformResponse: (response: any) => {
                return response.data
            }
        }),
        getSmartySuggestions: build.query({
            query: (payload) => {
                const params = {
                    requestParameters: {
                        Street: payload.search,
                        Selected: '',
                        Max: 10
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.SUGGEST_ADDRESS,
                    method: 'POST',
                    body: params,
                    providesTags: (result = [], error: any) => {
                        if (!result || error) return [{ type: 'SmartySuggestions', id: 'LIST' }]
                        return [
                            ...result.map(({ zip }) => ({ type: 'SmartySuggestions', id: zip } as const)),
                            { type: 'SmartySuggestions', id: 'LIST' },
                        ]
                    }
                }
            }
        }),
        postContactUs: build.mutation({
            query: (payload) => {
                const params = {
                    requestParameters: {
                        ...payload
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.CONTACT_US,
                    method: 'POST',
                    body: params,
                }
            },
        }),
    })
})

export const {
    useGetSiteInfoQuery,
    useGetSiteLogoQuery,
    useGetLocaleDataQuery,
    useGetCategoriesQuery,
    useGetSmartySuggestionsQuery,
    useLazyGetSmartySuggestionsQuery,
    usePostContactUsMutation
} = siteApi
