import React, { Component, useContext } from 'react';
import TopPanel from './TopPanel';
import TopHeader from './TopHeader';
import MegaMenu from './MegaMenu';
import { useSelector } from 'react-redux';
import { ToggleContext, useSwitching} from '../../../ToggleProvider';
import { useNavigate } from 'react-router-dom';
import { useSwitchSessionMutation } from '../../../services/auth';
import { PerspectiveSwitchModal } from '../modal/PerspectiveSwitchModal';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const sizingPortalEnabled = useSelector(state => state.commonReducer.sizingPortalEnabled);
    const currentCart = useSelector((state) => state.cartReducer);
    const { isToggled, setIsToggled, intentToSwitch, setIntentToSwitch } = useContext(ToggleContext);
    const navigate = useNavigate();
    const [switchSession, { isLoading }] = useSwitchSessionMutation();
    const { showSwitching, hideSwitching } = useSwitching();
    const { pathname } = useLocation();

    const toggle = () => {
        if(isToggled && currentCart.totalCartItems > 0){
            setIntentToSwitch(true);
            return;
        }else{
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        setIsToggled(!isToggled);
        // setIsLoading(true);
        switchSession({ Email, Password })
            .unwrap()
            .catch((error) => {
                //setShowError(true);
                //revert incase of error
                setIsToggled(!isToggled);
                navigate('/');
                window.location.reload();
            })
            .finally(() => {
                //  setIsLoading(false);
                navigate('/');
                window.location.reload();
                /*hideSwitching();*/
            });
        }
    };


    return (
        <>
            {
                sizingPortalEnabled &&
                <div className='bg-black'>
                    <div className="tabs container pay-tabs d-flex">
                        <input
                            type="checkbox"
                            id="tabs-checkbox"
                            className="tabs-checkbox"
                            checked={isToggled}
                            onChange={toggle}
                        />
                        <label className="tab" htmlFor="tabs-checkbox">
                            <span className={`tab-label ${!isToggled ? 'active' : ''}`}>Self-Pay</span>
                            <span className={`tab-label ${isToggled ? 'active' : ''}`}>Allotment</span>
                        </label>
                    </div>
                </div>
            }
            {pathname !== '/login' && <TopHeader />}

            <MegaMenu />
        </>
    );

}


export default Navbar;
