import { useEffect, useMemo, useRef, useState } from 'react';
import { FormGroup, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Config from '../../../helpers/Config';
import { nanoid } from '@reduxjs/toolkit'
import { calculateContrastRatio } from '../../../helpers/CommonHelper';
import ColorThief from 'colorthief';
import { useSelector } from 'react-redux';
import { reduxStore, useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import debounce from 'lodash.debounce';

const componentLabelMapping = {
    'P2L': 'Left Sleeve',
    'P1L': 'Left Chest',
    'P1R': 'Right Chest',
    'P2R': 'Right Sleeve',
}

const useImageLoaded = () => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef()

    const onLoad = () => {
        setLoaded(true)
    }

    useEffect(() => {
        if (ref.current && ref.current.complete) {
            onLoad()
        }
    })

    return [ref, loaded, onLoad]
}

const LogoComponent = ({ component, setContrastRatio, hexColor, hexCode, customOptions, index, setChangedVasData, mixed = false, isPreviewModal = false }) => {
    const dispatch = useAppDispatch();
    const [ref, loaded, onLoad] = useImageLoaded();
    const productActivity = useSelector(state => state.pdpReducer);
    const { embroidery : customEmbroidery } = useSelector(state => state.commonReducer).additionalSettings;
    const EmbPreviewBaseUrl = Config.ENV_EMBROIDERY_BASE_URL || "https://dataprovider.uabrands.com";
    const getGuid = () => {
        if (component.isCustom) {
            var key = component?.componentAttributes?.find(x => x.componentAttribute.toLowerCase() === "logo name")?.attributeValue;
            if (customOptions?.selected) {
                if (customOptions.selected === -1)
                    return '';
                return customEmbroidery?.customComponents[key].customLogo[customOptions.selected]?.guid;   
            }
            else return customEmbroidery?.customComponents[key].customLogo[0].guid;
        }
        return component?.componentAttributes?.find(x => x.componentAttribute.toLowerCase() === "logo number")?.attributeValue;
    }
    const resourceGuid = getGuid();


    useEffect(() => {
        if (!component.isCustom)
            return;
        
        if (customOptions?.selected == -1 && !productActivity.hasEmptyCustomLogo) {
            dispatch(rootAction.pdpActions.setHasEmptyCustomLogo({
                index,
                hasEmptyCustomLogo: true
            }));
        }

        let guid = getGuid();
        setChangedVasData(prevState => {
            const currentVas = reduxStore.getState().pdpReducer.currentEmbroideryConfig;
            const newState = JSON.parse(JSON.stringify(prevState));
            let logoComponentAttribute = newState[index].componentAttributes.find(x => x.componentAttribute.toLowerCase() === "logo number");
            if (guid === logoComponentAttribute.attributeValue)
                return newState;
            
            dispatch(rootAction.pdpActions.setShowEmbroideryError(!guid));
            dispatch(rootAction.pdpActions.setHasEmptyCustomLogo({
                index,
                hasEmptyCustomLogo: !guid
            }));

            logoComponentAttribute.attributeValue = guid;
            dispatch(rootAction.pdpActions.setCurrentEmbroideryConfig({ currentVas, changedVas: newState }));
            return newState;
        });
    }, [customOptions])

    const handImgLoad = () => {
        const colorThief = new ColorThief();
        if (ref.current && !hexColor) {
            const dominantColor = colorThief.getColor(ref.current, 25);
            setContrastRatio(calculateContrastRatio(hexCode.split('_').length > 1 ? hexCode.split('_')[0] : hexCode, dominantColor));
        }
    }

    if (!resourceGuid)
        return (<></>);

    return (
        <img ref={ref} crossOrigin='anonymous' onLoad={handImgLoad} className={`img-fluid ${mixed ? 'mixed' : ''} ${isPreviewModal ? 'preview' : ''}`} src={`${EmbPreviewBaseUrl}/CustomImages/${resourceGuid}/${resourceGuid}_preview.png`} />
    )
}

const TextComponent = ({ component, setChangedVasData, hexColor, readOnly, index, setHasTextInvalid }) => {
    const dispatch = useAppDispatch();
    const inputRef = useRef();
    const componentText = component?.componentAttributes?.find(x => x?.componentAttribute.toLowerCase() === 'freetext')?.attributeValue || '';
    const [currentFreeText, setCurrentFreeText] = useState(componentText.toLowerCase() === '|texttemplate|' ? '' : componentText);
    const [isInvalid, setIsInvalid] = useState(false);

    const handleTextChangeState = (e) => {
        setChangedVasData(prevState => {
            const currentVas = reduxStore.getState().pdpReducer.currentEmbroideryConfig;
            const newState = JSON.parse(JSON.stringify(prevState));
            const freeTextComponentAttribute = newState[index].componentAttributes.find(x => x.componentAttribute.toLowerCase() === "freetext");
            freeTextComponentAttribute.attributeValue = e.target.value;
            dispatch(rootAction.pdpActions.setCurrentEmbroideryConfig({ currentVas, changedVas: newState }));
            return newState;
        });
    }

    const handleTextChange = (e) => {
        if (e.target.value.trim() === '') {
            dispatch(rootAction.pdpActions.setHasEmptyEmbroideryText({
                index,
                hasEmptyEmbroideryText: true
            }));
            setIsInvalid(true);
        } else {
            dispatch(rootAction.pdpActions.setHasEmptyEmbroideryText({
                index,
                hasEmptyEmbroideryText: false
            }));
            setIsInvalid(false);
            dispatch(rootAction.pdpActions.setShowEmbroideryError(false));
        }
        setCurrentFreeText(e.target.value);
    }

    useEffect(() => {
        if (inputRef.current) {
            setIsInvalid(inputRef.current.value.trim() === '');
            dispatch(rootAction.pdpActions.setHasEmptyEmbroideryText({
                index,
                hasEmptyEmbroideryText: inputRef.current.value.trim() === ''
            }));
            dispatch(rootAction.pdpActions.setShowEmbroideryError(false));
        }
    }, [inputRef.current]);

    const debouncedTextChangeHandler = useMemo(() => {
        return debounce(handleTextChangeState, 500);
    }, []);

    return (
        (!readOnly ?
            <input
                id={`VasText_${nanoid()}`}
                ref={inputRef}
                value={currentFreeText}
                onChange={(e) => { handleTextChange(e); debouncedTextChangeHandler(e); }}
                type='text'
                maxLength='24'
                style={{
                    '--embroidery-text-color': `#${hexColor}`
                }} placeholder='Add Text' />
            :
            <b style={{ color: `#${hexColor}` }} className='d-block w-100'>{currentFreeText}</b>
        )
    )
}

const findMetaComponent = (component, componentAttribute) => {
    return component?.componentAttributes?.find(x => x.componentAttribute.toLowerCase() === componentAttribute);
}

const EmbroideryItem = ({ hexCode, toggleEmbItemModal, vasData, readOnly = false, isPreviewModal = false, customOptions = [] }) => {
    const components = vasData.map(x => {
        return {
            ...x,
            type: x?.componentAttributes.find(y => y.componentAttribute.toLowerCase() === "logo number") ?
                'logo' : 'text'
        }
    });
    const textComponents = vasData.filter(x => {
        return x?.componentAttributes.find(y => y.componentAttribute.toLowerCase() === "freetext")
    });
    const positionComponent = findMetaComponent(components?.[0], "placement");
    const hexColor = findMetaComponent(textComponents?.[0], "color")?.valueHexCode;
    const [contrastRatio, setContrastRatio] = useState();
    const minContrastRatio = useSelector(state => state.commonReducer.additionalSettings?.embroidery?.minContrastRatio || 1.9);
    const [changedVasData, setChangedVasData] = useState(vasData);
    const [hasTextInvalid, setHasTextInvalid] = useState(false);
    const [hasLogoInvalid, setHasLogoInvalid] = useState(false);
    const outlineEmbroideryError = useSelector(state => state.pdpReducer.outlineEmbroideryError);

    const handleClick = (e) => {
        if (toggleEmbItemModal && e.target.tagName !== 'INPUT') {
            toggleEmbItemModal(changedVasData);
        }
    }

    useEffect(() => {
        //watch changedVasData for text components and check if any of them are empty
        if(textComponents?.length > 0) {
            const hasEmptyEmbroideryText = changedVasData.some(x => x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "freetext" && y.attributeValue.toLowerCase().replace('|texttemplate|', '').trim() === ''));
            setHasTextInvalid(hasEmptyEmbroideryText);
        }

        if (components.filter(x => x.type === 'logo').length > 0) {
            const hasEmptyGuid = changedVasData.some(x => x.isCustom && x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "logo number" && !y.attributeValue));
            setHasLogoInvalid(hasEmptyGuid);
        }

    }, [changedVasData]);

    if (!hexCode)
        return null

    return (
        <div>
            <div className='embroidery-preview-item' role='button' onClick={handleClick} style={(contrastRatio < minContrastRatio) || ((hasTextInvalid || hasLogoInvalid) && outlineEmbroideryError) ? {
                border: '1px solid red',
                borderRadius: 8,
                padding: 2
            } : {}}>
                {!isPreviewModal && <small className='embroidery-preview-label'>{ componentLabelMapping?.[positionComponent?.attributeValue] || positionComponent?.webDisplayValue || positionComponent?.attributeValue}</small>}
                <div className={`embroidery-preview-content ${hexCode.toUpperCase() === '#FFFFFF' || (hexCode.split('_').length > 1 && hexCode.split('_')[0].toUpperCase() == '#FFFFFF') ? 'swatch-white' : ''}`} 
                    style={hexCode.split('_').length > 1 ? 
                        { background: hexCode.split('_')[0], '--embroidery-tile-color': hexCode.split('_')[0], '--embroidery-item-count': components.length } : 
                        { background: hexCode, '--embroidery-tile-color': hexCode, '--embroidery-item-count': components.length }}>
                    {components.map((x, i) => {
                        if (x.type === 'logo') {
                             return <LogoComponent key={x.clientId + 'logo'} component={x} isPreviewModal={isPreviewModal} setChangedVasData={setChangedVasData} index={i} customOptions={customOptions.find(o => o.clientId == x.clientId)} setContrastRatio={setContrastRatio} hexColor={hexColor} hexCode={hexCode} mixed={components.length > 1} />
                        } else {
                            return <TextComponent key={x.clientId + 'text'} component={x} readOnly={readOnly} setChangedVasData={setChangedVasData} hexColor={hexColor} index={i} setHasTextInvalid={setHasTextInvalid} />
                        }
                    })}
                </div>
            </div>
            {contrastRatio < minContrastRatio &&
                <small data-contrast={contrastRatio} className={`embroidery-preview-label mt-${!toggleEmbItemModal ? '2 d-flex' : 0}`} style={{ color: 'red', fontSize: 11 }}>
                    {!toggleEmbItemModal && <i className="fas fa-exclamation-circle fa-2x mr-2"></i>}
                    Color Issue {!toggleEmbItemModal ? '-' : <br />} Low Contrast
                </small>}
            {hasTextInvalid && outlineEmbroideryError &&
                <small data-contrast={contrastRatio} className={`embroidery-preview-label mt-${!toggleEmbItemModal ? '2 d-flex' : 0}`} style={{ color: 'red', fontSize: 11 }}>
                    {!toggleEmbItemModal && <i className="fas fa-exclamation-circle fa-2x mr-2"></i>}
                    Embroidery Text Required
                </small>}
            {hasLogoInvalid && outlineEmbroideryError &&
                <small data-contrast={contrastRatio} className={`embroidery-preview-label mt-${!toggleEmbItemModal ? '2 d-flex' : 0}`} style={{ color: 'red', fontSize: 11 }}>
                    {!toggleEmbItemModal && <i class="fas fa-exclamation-circle fa-2x mr-2"></i>}
                    Custom Embroidery Logo Required
                </small>}
        </div>
    )
}

const EmbItemModal = (props) => {
    const {
        open,
        toggle,
        vasData,
        hexCode,
        customOptions = []
    } = props;
    const positionComponent = vasData?.componentAttributes?.find(x => x.componentAttribute.toLowerCase() === "placement");
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;

    return (
        <Modal isOpen={open} toggle={toggle} centered={true} size='md' className='modal-embroidery-item'>
            <ModalHeader tag='h5' toggle={toggle} close={closeBtn}>
                {componentLabelMapping?.[positionComponent?.attributeValue] || positionComponent?.webDisplayValue || positionComponent?.attributeValue}
            </ModalHeader>
            <ModalBody>
                <EmbroideryItem hexCode={hexCode} vasData={vasData} isPreviewModal={true} customOptions={customOptions} />
            </ModalBody>
        </Modal>
    )
}

const VASTemplateV2 = (props) => {
    const dispatch = useAppDispatch();
    const { hexCode, vasData, readOnly, itemId } = props;
    const productActivity = useSelector(state => state.pdpReducer);

    const vasComponents =
    {
        'Right Sleeve': vasData?.filter(x => x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "placement" && y.attributeValue === "P2R")) ?? [],
        'Right Chest': vasData?.filter(x => x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "placement" && y.attributeValue === "P1R")) ?? [],
        'Left Chest': vasData?.filter(x => x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "placement" && y.attributeValue === "P1L")) ?? [],
        'Left Sleeve': vasData?.filter(x => x.componentAttributes.some(y => y.componentAttribute.toLowerCase() === "placement" && y.attributeValue === "P2L")) ?? [],
    };

    const [selectedEmbItem, setSelectedEmbItem] = useState(null);
    const [isEmbItemModalOpen, setIsEmbItemModalOpen] = useState(false);
    const toggleEmbItemModal = (vasData) => {
        if (!isEmbItemModalOpen) {
            setSelectedEmbItem(vasData);
        }
        setIsEmbItemModalOpen(!isEmbItemModalOpen);
    };
    const { embroidery : customEmbroidery } = useSelector(state => state.commonReducer).additionalSettings;

    const updateCustomComponent = (index, c) => {
        dispatch(rootAction.pdpActions.setCustomEmbroideryConfig(
            index > -1 ? 
            {
                ...c.selectItems[index],
                id: c.id,
                selectedIndex: index
            } :
            {
                id: c.id,
                selectedIndex: index
            }
        ));
    };

    const customComponents = vasData?.filter(x => x?.isCustom);
    const customComponentSelect = customComponents?.map((x, i) => {
        var id = x?.componentAttributes.find(y => y.componentAttribute.toLowerCase() === "logo name").attributeValue;
        var existingValue = x?.componentAttributes?.find(x => x.componentAttribute.toLowerCase() === "logo number");
        var guid = existingValue?.attributeValue ?? '';
        var value = existingValue?.attributeValueDescription ?? '';
        var customComponent = customEmbroidery?.customComponents[id];
        var selected = productActivity.customEmbroideryConfig[id] && !itemId ? productActivity.customEmbroideryConfig[id].selectedIndex : -1;
        var selectItems = customComponent.customLogo.map((y, j) => {
            if (y.guid === guid && selected == -1)
                selected = j;
            return {
                ...y,
                id: j
            }
        });

        if (selected === -1 && !customComponent.textNoOptionSelected)
            selected = 0;

        return { 
            id, 
            required: customComponent.required,
            textNoOptionSelected:  customComponent.textNoOptionSelected,
            selectItems, 
            selected,
            value,
            clientId: x.clientId
        };
    });

    useEffect(() => {
        customComponentSelect.forEach((c, i) => {
            if (c.selectItems?.length > 0 && c.required) {
                updateCustomComponent(c?.selected ?? 0, { 
                    selectItems: c.selectItems,
                    id: c.id,
                });
            }
        })
    }, []);

    return (
        <>
            {customComponentSelect?.length > 0 &&
                <div>
                    {customComponentSelect?.map((x, i) => 
                        !readOnly ?
                        <>
                            <div className="custom-embroidery-content my-2">{x.id}</div>
                            <FormGroup>
                                <Input
                                    type="select"
                                    id={'custom-emb-' + x.id}
                                    onChange={(e) => updateCustomComponent(e.target.value, x)}
                                    value={x.selected}>
                                    {(!x.required || x.textNoOptionSelected) &&
                                        <option value={-1}>{x.textNoOptionSelected}</option>}
                                    {x.selectItems.map((y, j) =>
                                        <option id={x.id + '-' + y.name} value={j}>{y.displayName}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </>
                        :
                        <>
                            <div className="custom-embroidery-content my-2">
                                <span className="mr-3">{x.id}:</span>
                                <span>{x.value}</span>
                            </div>
                        </>
                    )}
                </div>
            }
            <div className="embroidery-preview text-center">
                {Object.keys(vasComponents).map((key, i) => {
                    const id = vasComponents[key]?.map(x => x.clientId)?.join('_');
                    return (
                        vasComponents[key].length > 0 &&
                        <EmbroideryItem key={id + i} hexCode={hexCode} vasData={vasComponents[key]} customOptions={customComponentSelect} toggleEmbItemModal={toggleEmbItemModal} readOnly={readOnly} />
                    )
                })}
                {selectedEmbItem && <EmbItemModal open={isEmbItemModalOpen} toggle={toggleEmbItemModal} vasData={selectedEmbItem} hexCode={hexCode} customOptions={customComponentSelect} />}
            </div>
            <small><i>Click to enlarge</i></small>
        </>
    );
}
export default VASTemplateV2;