import { api } from './api.ts'
import { Product, ProductActivity, ProductAttribute, ProductColorInventory } from '../types/product.ts'
import Config from '../helpers/Config.js'
import { v4 as uuidv4 } from 'uuid';
import { pdpActions } from '../stateManagment/features/pdpSlice.ts';
import { nanoid } from '@reduxjs/toolkit';

// Define a service using a base URL and expected endpoints
export const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<Product, ProductActivity>({
      query: (payload) => {
        const params = {
          requestParameters: {
            ProductId: payload.productId?.split('-')[0] ?? '',
            recordValueJson: '[]',
            CategoryID: payload.categoryId,
            sku: payload.productId,
          }
        }
        return {
          url: Config.END_POINT_NAMES.GET_PRODUCT_DETAIL,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any): Product => {
        let productDetail = response.data?.[0];
        try {
          const embroideryTemplates = JSON.parse(productDetail.EmbroideryTemplates);
          embroideryTemplates.TemplateDescriptor.Templates.sort(function(a: any, b:any) {
            var textA = a.TemplateName.toUpperCase();
            var textB = b.TemplateName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          productDetail.EmbroideryTemplates = {
            TemplateDescriptor: {
              ...embroideryTemplates.TemplateDescriptor,
              Templates: embroideryTemplates.TemplateDescriptor.Templates.map((template: any, i: number) => ({
                ...template,
                index: i,
                VASData: template.VASData.map((component: any) => ({
                  ...component,
                  clientId: nanoid(),
                }))
              }))
            }
          }
        } catch (e) {
          productDetail.EmbroideryTemplates = {};
        }
        if (productDetail.ProductImagesJson.length === 0) {
          productDetail.ProductImagesJson = [{
            AttachmentURL: '/no-image.png',
            ColorID: '',
            ProductID: productDetail.ProductId,
            AttachmentID: 0,
            AttachmentName: 'No Image'
          }]
        }
        return {
          id: productDetail.ProductId,
          embroideryPresets: productDetail.EmbroideryTemplates,
          name: productDetail.ProductName,
          category: productDetail.AdjCategory,
          categoryName: productDetail.CategoryName,
          parentCategoryId: productDetail.ParentCategoryId,
          primaryCategoryId: productDetail.PrimaryCategoryId,
          shortDescription: productDetail.ShortDescription,
          fullDescription: productDetail.FullDescription,
          additionalInfo: productDetail.AdditionalInformation,
          fabricDescription: productDetail.FabricDetail,
          fabricInfo: productDetail.FabricInformation,
          sizeChart: productDetail.SizeChartHtml,
          type: productDetail.Type,
          isLegacySizeChart: productDetail.IsLegacySizeChart,
          price: productDetail.Price,
          maxPrice: productDetail.MaxPrice,
          minPrice: productDetail.MinPrice,
          style: productDetail.BaseStyle,
          minQuantity: productDetail.OrderMinimumQuantity,
          maxQuantity: productDetail.OrderMaximumQuantity,
          images: productDetail.ProductImagesJson?.map((image: any) => ({
            id: image.AttachmentID,
            name: image.AttachmentName,
            url: image.AttachmentURL,
            productId: image.ProductID,
            colorId: image.ColorID,
          })),
          colors: productDetail.ProductColorsJson?.map((color: any) => ({
            id: color.ColorID,
            name: color.ColorName,
            hexCode: color.HexCode,
            isOrderable: color.IsOrderable
          })) || [],
          sizes: productDetail.ProductSizesJson?.map((size: any) => ({
            id: size.SizeID,
            name: size.Name,
            shortName: size.ShortName,
            centimeters: size.Centimeters,
          })) || [],
          brand: productDetail.Brand,
          fitList: productDetail.FitOptionsList?.length > 1 ?
            productDetail.FitOptionsList
              ?.map((f: string) => f.toUpperCase())
              .sort((a: string, b: string) => {
                if (a === 'REGULAR') {
                  return -1;
                }
                if (b === 'REGULAR') {
                  return 1;
                }
                return 0;
              }) : [],
          seo: {
            title: productDetail.MetaTitle,
            description: productDetail.MetaDescription,
            keywords: productDetail.MetaKeywords,
          },
          embroideryEnabled: productDetail.EmbroideryAllowed,
          totalReviews: productDetail.TotalReviews,
          rating: productDetail.Rating,
          currentFit: productDetail.CurrentFit.toUpperCase(),
          itemClass: productDetail.ItemClass,
          embroideryPreviewBaseUrl: productDetail.EmbPreviewBaseUrl,
          isUAExclusive: productDetail.IsUAExclusive,
          colorMatchHelp: productDetail.ColorMatchHelp,
          variationAttributes: productDetail.VariationAttributes,
        } as Product
      },
      // keepUnusedDataFor: 2,
      providesTags: ['Product']
    }),
    getProducts: build.query<Product[], any>({
      query: (payload: any) => {
        const params = {
          requestParameters: {
            cacheBust: uuidv4(),
            SearchTerm: payload.searchTerm,
            TagID: null,
            MinPrice: null,
            MaxPrice: null,
            Rating: null,
            OrderByColumnName: '',
            PageNo: payload.paginationData.currentPage,
            PageSize: payload.paginationData.pageSize,
            recordValueJson: "[]",
            refinements: payload.refinements,
          },
        }
        return {
          url: Config.END_POINT_NAMES.GET_All_PRODUCTS,
          method: 'POST',
          body: params
        }
      },
      transformResponse: (response: any): Product[] => {
        let data = response.data;

        ['ProductBrands', 'ProductClass'].forEach((item) => {
          if (!data[item]?.options) return;
          data[item].options = data[item].options.filter((option: any) => option.hit_count > 0);
        })

        data.respObject = data.respObject.map((item: any) => {
          return {
            ...item,
            colorSwatches: item?.ProductColorsJson?.map((color: any) => {
              return {
                code: color?.ColorID,
                hex: color?.HexCode,
                imageUrl: color?.AttachmentURL,
                hoverImageUrl: color?.HoverImageURL,
                name: color?.ColorName
              }
            }) || [],
            defaultImage: item?.DefaultImage ? {
              imageUrl: item?.DefaultImage?.AttachmentURL,
              hoverImageUrl: item?.DefaultImage?.HoverImageURL
            } : {},
          };
        });

        return data
      },
      providesTags: ['Products'],
      keepUnusedDataFor: 2,
    }),
    getColorInventory: build.query<any, { product: Product, colorId: string }>({
      query: (payload) => {
        const params = {
          requestParameters: {
            ProductId: payload.product?.id,
            ColorID: payload.colorId,
            SizeArray: payload.product?.sizes?.map((size) => size.id),
          }
        }
        return {
          url: Config.END_POINT_NAMES.GET_COLOR_INVENTORY,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any): ProductColorInventory[] => {
        return response.data.map((colorInventory: any): ProductColorInventory => {
          return {
            upchargeText: colorInventory.UpchargeText,
            productId: colorInventory.ProductID,
            colorId: colorInventory.ColorID,
            sizeId: colorInventory.SizeID,
            orderable: colorInventory.Orderable,
            backOrderable: colorInventory.Backorderable,
            inStockDate: Date.parse(colorInventory.InStockDate),
            ats: colorInventory.Ats,
            stockLevel: colorInventory.StockLevel
          }
        })
      },
      keepUnusedDataFor: 2,
    }),
    getAllProductsAttributes: build.query<any, Product>({
      query: (payload) => {
        const params = {
          requestParameters: {
            ProductId: payload.id,
            recordValueJson: '[]'
          }
        }
        return {
          url: Config.END_POINT_NAMES.GET_PRODUCT_ALL_ATTRIBUTES_BY_ID,
          method: 'POST',
          body: params,

        }
      },
      transformResponse: (response: any): ProductAttribute[] => {
        return response.data.map((attribute: any): ProductAttribute => ({
          productAttributeMappingId: attribute.ProductAttributeMappingID,
          productAttributeId: attribute.ProductAttributeID,
          isRequiredAttribute: attribute.IsRequiredAttribute,
          priceAdjustmentType: attribute.PriceAdjustmentType,
          priceAdjustment: attribute.PriceAdjustment,
          additionalPrice: attribute.AdditionalPrice,
          attributeDisplayName: attribute.AttributeDisplayName,
          primaryKeyValue: attribute.PrimaryKeyValue,
          primaryKeyDisplayValue: attribute.PrimaryKeyDisplayValue,
          isPrimaryKeyValueSet: attribute.IsPrimaryKeyValueSet,
          colorId: attribute.Color,
        }))
      },
      providesTags: ['ProductAttributes'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: productAttributes } = await queryFulfilled
          dispatch(
            pdpActions.setMinPrice(
              productAttributes.reduce(
                (acc: number, attribute: ProductAttribute) =>
                  Math.min(acc, attribute.priceAdjustment || 0),
                Number.POSITIVE_INFINITY
              )
            )
          )
        } catch { }
      },
    }),
    getHowToMeasureContent: build.query<any, Product>({
      query: (payload) => {
        const contentId = `pdp-content-howtomeasure-${payload.itemClass.toLocaleLowerCase().split(' ').join('-')}`;
        const params = {
          requestParameters: {
            ContentID: contentId,
          }
        }
        return {
          url: Config.END_POINT_NAMES.GET_CONTENT_BY_ID,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any) => {
        return response?.data?.[0];
      },
      providesTags: (result: any) => {
        if (!result?.ContentKey) return [{ type: 'ProductHowToMeasure', id: 'LIST' }];
        return [{ type: 'ProductHowToMeasure', id: result.ContentKey }]
      },
    })
  })
})

export const {
  useGetProductQuery,
  useLazyGetProductQuery,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetColorInventoryQuery,
  useLazyGetColorInventoryQuery,
  useGetAllProductsAttributesQuery,
  useGetHowToMeasureContentQuery
} = productApi

export const {
  endpoints: {
    getProduct,
  }
} = productApi